<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row dense>
                <v-col cols="12">
                    <!-- 蓝色框 -->
                    <v-alert :dismissible="false" class="blue white--text" dark border="left" icon="fas fa-user">
                        <span>Users</span>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-2">
                    <v-row>
                        <v-col cols="auto" class="align-center mt-5">
                            <!-- 选择角色 -->
                            <v-btn small tile color='blue white--text' @click="role='all'" class="mr-3">ALL</v-btn>
                            <v-btn small tile color='blue white--text' @click="role='user'" class="mr-3">User</v-btn>
                            <v-btn small tile color='blue white--text' @click="role='trader'" class="mr-3">TRADER</v-btn>
                            <v-btn small tile color='blue white--text' @click="getAdminUserByPage(1)" class="mr-3">ADMIN</v-btn>                  
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field label="Email" v-model="email" @keyup.enter="searchUserByEmail()"></v-text-field>
                        </v-col>
                        <v-col cols="auto" class="mt-5">
                            <v-icon @click="searchUserByEmail()">search</v-icon>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pt-0 px-2">
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table :headers="UserHeaders" :items="users" hide-default-footer v-if="!loading">
                        <!-- 表头上侧 -->
                        <!-- <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-btn small tile color='blue white--text' @click="role='all'" class="mr-3">ALL</v-btn>
                                <v-btn small tile color='blue white--text' @click="role='user'" class="mr-3">User</v-btn>
                                <v-btn small tile color='blue white--text' @click="role='trader'" class="mr-3">TRADER</v-btn>
                                <v-btn small tile color='blue white--text' @click="getAdminUserByPage(1)" class="mr-3">ADMIN</v-btn>
                                <v-row class="mx-auto">
                                    <v-col cols="2" class="mt-4 mr-1">
                                        <v-text-field label="Email" v-model="email" @keyup.enter="searchUserByEmail()"></v-text-field>
                                    </v-col>
                                    <v-col class="mt-5">
                                        <v-icon @click="searchUserByEmail()">search</v-icon>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-divider />
                        </template> -->
                        <!-- 表数据 -->
                        <template v-slot:body="{ user }">
                            <tbody>
                                <tr v-for="(user,index) in users" :key="user.id">
                                    <td>{{(indexNo-1)* pageSize +(index+1)}}</td>
                                    <td>
                                        <v-avatar color="primary" size="40">
                                            <img v-show=" user.avatar  != null" :src=" user.avatar " />
                                            <span v-show=" user.avatar  == null" class="headline white--text">
                                                {{ user.username.trim().substring(0, 1).toLocaleUpperCase() }}
                                            </span>
                                        </v-avatar>
                                    </td>
                                    <td>{{ user.username }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ user.role }}</td>
									<td>{{ user.readIndex }}</td>
                                    <td>{{ common.dateFormat(user.timestamp) }}</td>
                                    <td>
                                        <v-btn tile icon @click="editUser(user)">
                                            <v-icon small>fas fa-edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!loading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="totalElements>0 && !adminPagination">
                            <v-pagination circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pageNo" :length="totalPage" @input="getUserList" :total-visible="15">
                            </v-pagination>
                        </div>
                        <div class="text-xs-center" v-if="totalElements>0 && adminPagination">
                            <v-pagination circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pageNo" :length="totalPage" @input="getAdminUserByPage"
                                :total-visible="15">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <!-- 用户模态框 -->
        <v-dialog v-model="userDialog" persistent max-width="650px">
            <v-card tile>
                <v-card-title>
                    <span class="fs-24">Edit Role</span>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog" icon><v-icon>fas fa-times</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container class="mt-3">
                        <v-form ref="userForm">
                            <v-row dense>
                                <v-col cols="12" md="1" class="pl-0">
                                    <v-avatar color="primary" size="40">
                                        <img v-show=" userForm.avatar  != null" :src=" userForm.avatar " />
                                        <span v-show=" userForm.avatar  == null" class="headline white--text">
                                            {{ (userForm.username+"").trim().substring(0, 1).toLocaleUpperCase() }}
                                        </span>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field :disabled="true" label="User Name" v-model="userForm.username">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="5">
                                    <v-text-field :disabled="true" label="Email" v-model="userForm.email">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select v-model="userForm.role" :items="userRole" label="Role"></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn class="blue--text" text @click="closeDialog">Cancel</v-btn> -->
                    <v-btn class="blue--text mr-5" text @click="updateUserRole" :loading="userLoading">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import {
        mapGetters
    } from "vuex";
    export default {
        data() {
            return {
                users: [],
                UserHeaders: [
                     {
                        text: 'No',
                        value: 'no',
                        sortable: false,
                    },
                    {
                        text: 'Avatar',
                        value: 'avatar',
                        sortable: false,
                    },
                    {
                        text: 'User Name',
                        value: 'username',
                        sortable: false,
                    },
                    {
                        text: 'Email',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'Role',
                        value: 'role',
                        sortable: false,
                    },
					{
					    text: 'Read Index',
					    value: 'readIndex',
					    sortable: false,
					},
                    {
                        text: 'Time',
                        value: 'timestamp',
                        sortable: false,
                    },
                    {
                        text: 'Action',
                        value: 'action',
                        sortable: false,
                    },
                ],
                adminPagination: false,
                role: "all",
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 15,
                userDialog: false,
                userForm: {},
                userRole: ["USER", "TRADER", "USER | ADMIN", "TRADER | ADMIN"],
                userLoading: false,
                indexNo:0,
                loading:true,
                email:'',
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            role(nweVal, oldVal) {
                if (nweVal != null && nweVal != oldVal && nweVal != "") {
                    this.email = "";
                    this.pageNo = 1;
                    this.getUserList(1)
                }
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login',
                })
            };
            // if (Number(localStorage.getItem("iobots-admin-expires")) - ((new Date()).getTime()) > 0) {
            //     localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 60 * 60 * 2)
            // };
            this.getUserList(1);
        },
        created() {

        },
        methods: {
            // 分页查询用户
            getUserList(pageNo) {
                this.users = [];
                this.loading = true;
                this.adminPagination = false;
                let api = config.baseUrl + '/user/private/getUsers/' + this.role + '/' + (pageNo - 1) + '/' + this
                    .pageSize
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.users = response.data.result.userList;
                            this.totalElements = response.data.result.totalElements;
                            this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                            this.indexNo = pageNo;
                            this.loading = false;
                        }
                    },
                )
            },
            // 分页查询管理员用户
            getAdminUserByPage(pageNo) {
                this.users = [];
                this.loading = true;
                this.pageNo = 1;
                this.adminPagination = true;
                this.role=null;
                let api = config.baseUrl + '/user/private/getAdminUserByPage/' + (pageNo - 1) + '/' + this.pageSize;
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.users = response.data.result.userList;
                            this.totalElements = response.data.result.totalElements;
                            this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                            this.indexNo = pageNo;
                            this.loading = false;
                        }
                    },
                )
            },
            // 根据邮箱查询用户
            searchUserByEmail(){
                if(this.email != '' && this.email != null){
                    this.users = [];
                    this.loading = true;
                    this.adminPagination = false;
                    this.totalElements = 0;
                    this.role = "";
                    let api = config.baseUrl + '/user/private/getUserByEmail?email=' + this.email;
                    this.$http.get(api).then(
                        response => {
                            if (response.data.code == 200) {
                                this.users = response.data.result;
                                this.loading = false;
                            }else{
                                this.loading = false;
                            }
                        },
                    )
                }
            },
            // 编辑用户
            editUser(user) {
                this.userDialog = true;
                // 拷贝一个对象并赋值
                this.userForm = JSON.parse(JSON.stringify(user));
            },
            // 更新用户角色
            updateUserRole() {
                this.userLoading = true;
                if (this.$refs.userForm.validate()) {
                    let api = config.baseUrl + '/user/admin/private/updateUserRole';
                    this.$http.post(api, this.userForm).then(response => {
                        let data = response.data;
                        if (data.code == 200) {
                            this.$store.dispatch('snackbarMessageHandler', "修改成功");
                            if(this.role==null){
                                this.getAdminUserByPage(1);
                            }else{
                                this.getUserList(1);
                            }
                            this.closeDialog();
                        }
                    }, error => {
                        this.$store.dispatch('snackbarMessageHandler', "error");
                        this.userLoading = false;
                    });
                }
            },
            // 关闭弹窗
            closeDialog() {
                this.userDialog = false;
                this.userLoading = false;
            },
            // 首字母大写
            toFirstLetterUpperCase(message) {
                if (message) {
                    return message.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
                } else {
                    return null;
                }
            },
            // 日期格式化
            // dateFormat: function (time) {
            //     var date = new Date(time);
            //     var year = date.getFullYear();
            //     /* 在日期格式中，月份是从0开始的，因此要加0
            //      * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
            //      * */
            //     var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
            //     var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            //     // 拼接
            //     return ( month + "/" + day + "/" + year);
            // },
        }
    }
</script>